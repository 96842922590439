<template>
  <div class="reward-result" :class="{skeleton : !state.loaded }">
    <template v-if="$store.state.account.loggedIn">
      <div class="envelope">
        <div class="symbol">
          <span class="img" style="background-image: url(/assets/ico/page.order.paid.heart.svg)"></span>
        </div>
        <div class="name font-md">
          <router-link :to="`/reward/${$route.params.projectSeq}`" title="프로젝트 조회">
            <b>{{ state.project.projectName }}</b>
          </router-link>
        </div>
        <div class="txt font-base">
          <span>프로젝트를 후원해주셔서 감사합니다.</span>
        </div>
        <div class="ask">
          <div class="virtual" v-if="state.investor.paymethod === 'virtual'">
            <span class="info">{{ state.investor.virtualCentercd }}({{ state.investor.rvirno }}) 계좌로</span>
            <div>
              <b>{{ $lib.getNumberFormat(computedPaidAmount) }}원</b>
              <span>을 입금해주세요.</span>
            </div>
          </div>
          <div v-else-if="state.investor.paymethod === 'real'">
            <span>실시간 계좌이체로 </span>
            <b>{{ $lib.getNumberFormat(computedPaidAmount) }}원을 </b>
            <br class="d-block d-sm-none"/>
            <span>결제 완료하였습니다.</span>
            <div class="pt-2">결제 완료 시각: {{ state.investor.handlingDate }}</div>
          </div>
          <div v-else-if="['card', 'naverpay'].includes(state.investor.paymethod)">
            <span v-if="state.investor.paymethod === 'card'">카드로 </span>
            <span v-else>네이버페이로 </span>
            <b>{{ $lib.getNumberFormat(computedPaidAmount) }}원을 </b>
            <br class="d-block d-sm-none"/>
            <template v-if="state.project.fundingType === 'A'">
              <span>결제 예약하였습니다.</span>
              <div class="pt-2">예약 결제 시각: {{ state.investor.scheduleat }}</div>
            </template>
            <template v-else>
              <span>결제 완료하였습니다.</span>
              <div class="pt-2">결제 완료 시각: {{ state.investor.handlingDate }}</div>
            </template>
          </div>
          <div v-else-if="['omcpay', 'point', 'op_po'].includes(state.investor.paymethod)">
            <span v-if="state.investor.paymethod === 'omcpay'">포인트를</span>
            <span v-else-if="state.investor.paymethod === 'point'">쿠폰을</span>
            <span v-else>포인트 및 쿠폰을</span>
            <span> 사용하여 </span>
            <b>{{ $lib.getNumberFormat((Number(state.investor.omcpay) || 0) + (Number(state.investor.couponPoint) || 0)) }}원을 </b>
            <br class="d-block d-sm-none"/>
            <span>결제 완료하였습니다.</span>
          </div>
          <div v-else>
            <span>Please Wait a moment. Thank you for waiting.</span>
          </div>
        </div>
        <div class="guide">
          <div>
            <span>결제 확인 및 후원 내역 변경은 </span>
            <span>
              <br class="d-block d-sm-none"/>
              <router-link to="/mypage/participant/reward" class="color-point">마이페이지</router-link>
              <span>에서 가능합니다.</span>
            </span>
          </div>
        </div>
        <div class="sns">
          <div class="message">
            <span>프로젝트가 더 많은 분들에게 </span>
            <br class="d-block d-sm-none"/>
            <span>알려질 수 있도록 도와주세요.</span>
          </div>
          <div class="wrapper">
            <button class="btn img facebook" title="페이스북으로 공유" @click="share('facebook')" style="background-image: url(/assets/ico/common.sns.facebook.svg)"></button>
            <button class="btn img twitter" title="트위터로 공유" @click="share('twitter')" style="background-image: url(/assets/ico/common.sns.twitter.svg)"></button>
            <button class="btn img" title="카카오톡으로 공유" @click="share('kakaolink')" style="background-image: url(/assets/ico/common.sns.kakaotalk.svg)"></button>
            <button class="btn img" title="밴드로 공유" @click="share('band')" style="background-image: url(/assets/ico/common.sns.band.svg)"></button>
          </div>
        </div>
      </div>
      <div class="background" ref="backgroundRef" v-if="$env.device === 'desktop' && $env.browser !== 'IE' && state.visible">
        <Confetti :finish="removeBackground"/>
      </div>
      <div class="recommends" v-if="state.loaded">
        <div class="container">
          <DetailRelatives :title="`${$store.state.account.memberName}님의 관심이 필요해요!`" :projectSeq="Number(state.project.projectSeq)" :projectCate="state.project.projectCate"/>
        </div>
      </div>
    </template>
    <NoLogin v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {computed, defineComponent, onUnmounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import Number from "@/components/Number";
import Phone from "@/components/Phone";
import Address from "@/components/Address";
import NoLogin from "@/components/NoLogin";
import DetailRelatives from "@/pages/project/DetailRelatives";
import router from "@/scripts/router";
import store from "@/scripts/store";
import Confetti from "@/components/Confetti";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import {useGtm} from "@gtm-support/vue2-gtm";
import caches from "@/scripts/caches";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "pageRewardOrderResult";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {DetailRelatives, Confetti, NoLogin, Address, Phone, Number},
  setup() {
    const component = new Component(async () => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage", {replace: true});
      }

      const investorSeq = router.app.$route.params.investorSeq;

      // http 캐시 초기화
      caches.resetHttps();

      if (!router.app.$route.query.modals) {
        state.timeout = setTimeout(() => {
          state.project.openId && store.commit("openModal", {
            name: "Follow",
            params: {
              memberSeq: state.project.openId,
            }
          });
        }, 2000);
      }

      state.loaded = false;
      const res1 = await http.get(`/api/reward/projects/${projectSeq}`).catch(httpError((err) => {
        switch (err?.response?.status) {
          case 403:
            store.commit("setSwingMessage", "이 페이지를 조회하실 수 있는 권한이 없습니다.");
            return router.replace({path: "/"});
        }
      }));

      state.loaded = true;

      if (res1?.error) {
        return;
      }

      state.loaded = false;
      const res2 = await http.get(`/api/reward/project/${projectSeq}/payments/result/${investorSeq}`).catch(httpError((err) => {
        switch (err?.response?.status) {
          case 403:
            store.commit("setSwingMessage", "이 페이지를 조회하실 수 있는 권한이 없습니다.");
            return router.replace({path: "/"});
        }
      }));

      state.loaded = true;

      if (res2?.error) {
        return;
      }

      state.visible = true;
      state.project = res1.data.body.project;
      state.investor = res2.data.body;

      await store.dispatch("setDocumentTitle", state.project.projectName);
      await store.dispatch("initSnsShare");

      if (Array.isArray(res1.data.body.thumbNailList) && res1.data.body.thumbNailList.length) {
        state.project.thumbNailPath = res1.data.body.thumbNailList[0]?.thumbFilePath;
      }

      // GTM으로 이벤트 전달
      const loggingMsg = "Event rewardProject Purchase";
      try {
        const gtm = useGtm();
        if (gtm.enabled()) {
          // console.log(loggingMsg);
          // 결제 완료 금액
          let dataLayerProjectName = state.project.projectName;
          let dataLayerAmount = state.investor.expenseAmt;
          let dataLayerEvent = "EventRewardProjectPurchase";

          window.dataLayer?.push({
            event: dataLayerEvent,
            "project.projectSeq": state.project.projectSeq,
            "project.projectName": dataLayerProjectName,
            "amount": dataLayerAmount,
          });
        }
      } catch (err) {
        // 데이터 전송이 실패하면 콘솔로그출력
        console.warn(`Failed ${loggingMsg} - ${err}`);
      }
    });

    const state = reactive({
      loaded: false,
      visible: false,
      timeout: 0,
      project: {
        projectName: "Wait a moment",
        builderName: "Wait a moment",
        thumbNailPath: "",
      },
      investor: {
        paymethod: "",
        virtualCentercd: "",
        rvirno: "",
        expenseAmt: 0,
        scheduleAt: "",
      }
    });

    const backgroundRef = ref();

    const computedPaidAmount = computed(() => {
      return state.investor.expenseAmt - state.investor.omcpay - state.investor.couponPoint;
    });

    const projectSeq = router.app.$route.params.projectSeq;

    const share = (platform) => {
      const url = location.origin + `/reward/${projectSeq}`;
      const title = state.project.projectName;
      const desc = state.project.simpleText;
      const imgUrl = store.getters.thumbnailUrl(state.project.thumbNailPath);
      const imgWidth = definitions.thumbnail.width;
      const imgHeight = definitions.thumbnail.height;
      store.dispatch("share", {platform, title, desc, url, imgUrl, imgWidth, imgHeight});
    };

    const removeBackground = () => {
      backgroundRef.value.remove();
    };

    onUnmounted(() => {
      clearTimeout(state.timeout);
    });

    return {component, state, backgroundRef, computedPaidAmount, share, removeBackground};
  }
});
</script>

<style lang="scss" scoped>
.reward-result {
  padding-top: $px25;

  .envelope {
    text-align: center;
    margin: 0 auto;
    padding: $px65 0;
    background-image: url(/assets/img/page.order.paid.envelope.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 50%;
    height: 720px;
    max-width: 800px;

    > div {
      position: relative;
      z-index: 1;

      &.symbol {
        height: $px50;
        display: inline-block;
        overflow: hidden;

        .img {
          width: $px40;
          height: $px40;
        }
      }

      &.name {
        span, b {
          vertical-align: top;
        }

        b {
          display: inline-block;
          max-width: $px420;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 $px15;
        }
      }

      &.txt {
        padding-top: $px3;
      }

      &.ask {
        margin-top: $px17;
      }

      &.guide {
        padding-top: $px10;
      }

      &.sns {
        padding-top: $px8;

        > .wrapper {
          display: inline-block;
          padding-top: $px20;

          .btn {
            width: $px40;
            height: $px40;
            margin: $px5;
            box-shadow: none;
            border-radius: 50%;
            background-position: 50%;

            &.facebook {
              background-color: #445d98;
            }

            &.twitter {
              background-size: 120%;
              background-color: #2aade3;
            }
          }
        }
      }
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .recommends {
    border-top: $px1 solid #eee;
    background: #fff;
    padding: $px50 0 $px35 0;
    margin-top: $px-300;
  }

  &.skeleton {
    .envelope {
      filter: grayscale(1);

      .symbol {
        .img {
          @include skeleton;
        }
      }

      .name > a {
        @include skeleton;
      }

      .txt > span {
        @include skeleton;

        span {
          color: inherit !important;
        }
      }

      .ask > div > span {
        @include skeleton;
      }

      .guide > div > span {
        @include skeleton;

        a {
          visibility: hidden
        }
      }

      .sns {
        .message > span, .btn {
          @include skeleton;
        }
      }
    }
  }

  @media(max-width: 767px) {
    .envelope {
      height: auto;
      background: none;
      padding: $px15;

      > div {
        &.symbol {
          height: $px85;

          .img {
            width: $px70;
            height: $px70;
          }
        }

        &.name b {
          max-width: 100%;
        }
      }
    }

    .recommends {
      margin-top: $px20;
      padding-top: $px30;
    }
  }
}
</style>